var _a;
import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    dataKeywork: null,
    currentMenu: "",
    currentMenuLandingPage: "",
    status: "idle",
    error: ""
};
var globalsSlice = createSlice({
    name: 'globals',
    initialState: initialState,
    reducers: {
        setGlobalKeywork: function (state, _a) {
            var data = _a.payload.data;
            state.dataKeywork = data;
        },
        setCurrentMenu: function (state, _a) {
            var data = _a.payload.data;
            state.currentMenu = data ? data : "";
        },
        setMenuLandingPage: function (state, _a) {
            var data = _a.payload.data;
            state.currentMenuLandingPage = data ? data : "";
        }
    },
    extraReducers: function (builder) {
    }
});
export var setGlobalKeywork = (_a = globalsSlice.actions, _a.setGlobalKeywork), setCurrentMenu = _a.setCurrentMenu, setMenuLandingPage = _a.setMenuLandingPage;
export var getGlobalKeywork = function (state) { return state.globals ? state.globals.dataKeywork : null; };
export var getCurrentMenu = function (state) { return state.globals ? state.globals.currentMenu : null; };
export var getCurrentMenuLandingPage = function (state) { return state.globals ? state.globals.currentMenuLandingPage : null; };
export default globalsSlice.reducer;
