var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
/* eslint-disable react/no-unknown-property */
import { BellIcon } from "@heroicons/react/24/outline";
import { getMe } from "api";
import { getNotificationData, markAsReadNotification, } from "api/notification-repository";
import { cloneDeep } from "lodash";
import moment from "moment";
import "moment/locale/vi";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { USER_DATA_KEY } from "utils/StorageKeys";
import { selectAllNotifications } from "../../../store/notification/slice";
import * as Constant from "../../../utils/Constants";
import { useAppSelector } from "../../../utils/hook";
import NotificationItem from "./NotificationItem";
import { getItem, storeItem } from "utils/LocalStorageUtil";
import { NotificationType } from "../../../utils/Constants";
var NotificationDropdown = function (props) {
    var notificationGlobalCountData = props.notificationGlobalCountData;
    var navigate = useNavigate();
    var user = getItem(USER_DATA_KEY);
    var _a = useTranslation(), t = _a.t, i18n = _a.i18n;
    var _b = useState(false), open = _b[0], setOpen = _b[1];
    var _c = useState(false), isLoading = _c[0], setLoading = _c[1];
    var _d = useState(true), hasMore = _d[0], setMore = _d[1];
    var _e = useState([]), notificationData = _e[0], setNotificationData = _e[1];
    var _f = useState([]), notificationsOfToday = _f[0], setNotificationsOfToday = _f[1];
    var _g = useState({
        size: 10,
        page: 0,
    }), notificationOption = _g[0], setOption = _g[1];
    var _h = useState(0), numberNotRead = _h[0], setNumberNotRead = _h[1];
    // selectors to access state
    var notificationGlobalSelectors = useAppSelector(selectAllNotifications);
    var useOutsideAlerter = function (ref) {
        useEffect(function () {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return function () {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    };
    var notificationRef = useRef(null);
    useOutsideAlerter(notificationRef);
    // useEffect(() => {
    //     getNotification("DEFAULT");
    // }, [])
    useEffect(function () {
        if (notificationGlobalSelectors) {
            setNumberNotRead(notificationGlobalSelectors);
        }
    }, [notificationGlobalSelectors]);
    var getNotification = function (type) { return __awaiter(void 0, void 0, void 0, function () {
        var size, page, params, data, result, notificationPush, notificationOfTodayTemp_1, notificationsTemp_1, affiliateRequestNotification, userInfo, data_1, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    setLoading(true);
                    size = notificationOption.size;
                    page = notificationOption.page;
                    if (type === "DEFAULT") {
                        page = 0;
                    }
                    else {
                        page = page + 1;
                    }
                    params = {
                        page: page,
                        size: size,
                    };
                    return [4 /*yield*/, getNotificationData(params)];
                case 1:
                    data = _a.sent();
                    return [4 /*yield*/, getMe()];
                case 2:
                    result = _a.sent();
                    setOption({
                        page: page,
                        size: size,
                    });
                    notificationPush = type
                        ? data && data.data
                        : __spreadArray(__spreadArray([], notificationsOfToday, true), notificationData.concat(data.data), true);
                    setMore(data.data.length >= 10);
                    notificationOfTodayTemp_1 = [];
                    notificationsTemp_1 = [];
                    (cloneDeep(notificationPush) || []).map(function (notification) {
                        if (notification.createdAt &&
                            isToday(new Date(notification.createdAt))) {
                            notificationOfTodayTemp_1 = __spreadArray(__spreadArray([], notificationOfTodayTemp_1, true), [notification], false);
                        }
                        else {
                            notificationsTemp_1 = __spreadArray(__spreadArray([], notificationsTemp_1, true), [notification], false);
                        }
                    });
                    setNotificationsOfToday(notificationOfTodayTemp_1);
                    setNotificationData(notificationsTemp_1);
                    affiliateRequestNotification = data.data.find(function (data) {
                        return data.type.name === Constant.NOTIFICATION_APPROVED_AFFILIATE ||
                            data.type.name === Constant.NOTIFICATION_REJECTED_AFFILIATE;
                    });
                    if (affiliateRequestNotification) {
                        userInfo = JSON.parse(user);
                        data_1 = {};
                        if (affiliateRequestNotification.type.name ===
                            Constant.NOTIFICATION_APPROVED_AFFILIATE) {
                            data_1 = __assign(__assign({}, userInfo), { affiliateStatus: 1 });
                            storeItem(USER_DATA_KEY, JSON.stringify(data_1));
                        }
                        else if (affiliateRequestNotification.type.name ===
                            Constant.NOTIFICATION_REJECTED_AFFILIATE) {
                            data_1 = __assign(__assign({}, userInfo), { affiliateStatus: 2 });
                            storeItem(USER_DATA_KEY, JSON.stringify(data_1));
                        }
                    }
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _a.sent();
                    setLoading(false);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var handleClick = function (event) {
        setOpen(!open);
        if (!open) {
            getNotification("DEFAULT");
        }
    };
    var onClickNotification = function (event, notification) { return __awaiter(void 0, void 0, void 0, function () {
        var detailLink, error_1;
        var _a, _b, _c;
        return __generator(this, function (_d) {
            switch (_d.label) {
                case 0:
                    _d.trys.push([0, 3, , 4]);
                    if (!notification) return [3 /*break*/, 2];
                    return [4 /*yield*/, markAsReadNotification(notification.id, { status: 2 })];
                case 1:
                    _d.sent();
                    detailLink = "";
                    switch ((_a = notification === null || notification === void 0 ? void 0 : notification.type) === null || _a === void 0 ? void 0 : _a.name) {
                        case NotificationType.SanhSendToEndUser:
                            detailLink = (_b = notification === null || notification === void 0 ? void 0 : notification.contentData) === null || _b === void 0 ? void 0 : _b.purchasedURL;
                            break;
                        default:
                            detailLink = (_c = notification === null || notification === void 0 ? void 0 : notification.contentData) === null || _c === void 0 ? void 0 : _c.entityDetailLink;
                            break;
                    }
                    detailLink && window.location.replace(detailLink);
                    _d.label = 2;
                case 2: return [3 /*break*/, 4];
                case 3:
                    error_1 = _d.sent();
                    console.log(error_1);
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var isToday = function (date) {
        return moment(date).isSame(moment(), "day");
    };
    return (React.createElement("div", { className: "dropdowns-wrapper mr-3 max-[500px]:mr-0", ref: notificationRef },
        React.createElement("div", { className: "dropdown-container" },
            React.createElement("div", { className: "notifications dropdown dd-trigger" },
                React.createElement("a", { className: "\n                            cursor-pointer\n                            text-gray-500\n                            hover:text-gray-700\n                            focus:text-gray-700\n                            mr-4\n                            dropdown-toggle\n                            hidden-arrow\n                            flex items-center\n                        ", onClick: handleClick, role: "button", "aria-expanded": "false" },
                    React.createElement(BellIcon, { className: "w-6 h-6 text-neutralPrimary" }),
                    numberNotRead ? (React.createElement("span", { className: "notifications-number text-white !bg-brand absolute rounded-full text-[8px] -mt-4 ml-4 py-0 px-1.5" }, numberNotRead >= 100 ? "99+" : numberNotRead)) : null)),
            open ? (React.createElement("div", { className: "custom-select-dropdown custom-select-notification arrow rounded-[10px] !px-3 !py-2", style: { position: "absolute", zIndex: 99999999 } },
                React.createElement("div", { className: "dropdown-header !p-0 !pb-1 !border-b-0" },
                    React.createElement("span", { className: "triangle" }),
                    React.createElement("span", { className: "text-base !font-medium text-neutralPrimary !normal-case" }, t("common.notification"))),
                React.createElement("div", { className: "dropdown-body dropdown-body-notification !bg-white", id: "scrollableNotification" },
                    React.createElement(InfiniteScroll, { dataLength: notificationData.length, next: getNotification, hasMore: hasMore, loader: React.createElement("div", { className: "text-center" },
                            React.createElement("span", null, "Loading....")), scrollableTarget: "scrollableNotification", endMessage: React.createElement("p", { className: "text-center text-neutralPrimary !font-normal" }, t("common.notification_see_all")) },
                        notificationsOfToday && notificationsOfToday.length ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "notification-text flex-1 !text-base !font-normal !text-neutralPrimary !px-0" },
                                React.createElement("p", { className: "my-0" }, t("common.today"))),
                            notificationsOfToday.map(function (notification) { return (React.createElement(NotificationItem, { key: notification === null || notification === void 0 ? void 0 : notification.id, currentLanguage: i18n.language, notification: notification, onClickNotification: onClickNotification })); }))) : null,
                        notificationData && notificationData.length ? (React.createElement(React.Fragment, null,
                            React.createElement("div", { className: "notification-text flex-1 !text-base !font-normal !text-neutralPrimary !px-0 border-0 border-t border-t-underline border-solid mt-2 pt-2" },
                                React.createElement("p", { className: "my-0" }, t("common.older"))),
                            notificationData.map(function (notification) { return (React.createElement(NotificationItem, { key: notification === null || notification === void 0 ? void 0 : notification.id, currentLanguage: i18n.language, notification: notification, onClickNotification: onClickNotification })); }))) : null)),
                React.createElement("div", { className: "dropdown-footer !border-t-0" }))) : null),
        React.createElement("div", { className: "dropdown-container" },
            React.createElement("div", { className: "messages dropdown" },
                React.createElement("span", { className: "fa fa-envelope-o" })))));
};
export default NotificationDropdown;
