export var TOKEN_KEY = 'loyatyAccessToken';
export var REFRESH_TOKEN_KEY = 'loyatyRefreshToken';
export var OPENLIVE_TOKEN_KEY = 'accessToken';
export var OPENLIVE_REFRESH_TOKEN_KEY = 'refreshToken';
export var USER_DATA_KEY = 'views';
export var PERMISSION_DATA_KEY = 'permissionData';
export var SHIPPING_ADDRESS = 'shippingAddress';
export var LANGUAGE_KEY = 'language';
export var LOGIN_REFERER = 'loginReferer';
export var REMEMBER_ME = 'rememberMe';
export var IS_NEED_UPDATE_BRAND_INFO = 'isNeedUpdateBrandInfo';
