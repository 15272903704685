import { BellIcon, CheckCircleIcon } from "@heroicons/react/24/outline";
import "moment/locale/vi";
import React from "react";
import { formatNotificationTime } from "utils/CommonUtil";
import { NotificationType } from "utils/Constants";
var NotificationIcon = function (_a) {
    var _b, _c;
    var notification = _a.notification;
    var type = (_b = notification === null || notification === void 0 ? void 0 : notification.type) === null || _b === void 0 ? void 0 : _b.name;
    return (React.createElement("div", { className: "notification-image" }, (notification === null || notification === void 0 ? void 0 : notification.contentData) &&
        ((notification === null || notification === void 0 ? void 0 : notification.contentData.brandLogoUrl) ||
            (notification === null || notification === void 0 ? void 0 : notification.contentData.logoUrl)) ? (React.createElement("img", { src: (notification === null || notification === void 0 ? void 0 : notification.contentData.brandLogoUrl) ||
            (notification === null || notification === void 0 ? void 0 : notification.contentData.logoUrl), alt: "obranding-brand-logo", className: "w-8 h-8" })) : ((_c = Object.values(NotificationType)) === null || _c === void 0 ? void 0 : _c.includes(type)) ? (React.createElement(CheckCircleIcon, { width: 20, className: "text-success p-[2px]" })) : (React.createElement(BellIcon, { width: 20, className: "text-brand p-[2px]" }))));
};
var NotificationItem = function (_a) {
    var notification = _a.notification, currentLanguage = _a.currentLanguage, onClickNotification = _a.onClickNotification;
    return (React.createElement("div", { key: notification === null || notification === void 0 ? void 0 : notification.icon, className: "flex justify-between gap-2 notification !bg-white !p-0 !py-1 !border-b-0 ".concat(notification && (notification === null || notification === void 0 ? void 0 : notification.status) == 2 ? "" : "new"), onClick: function (e) { return onClickNotification(e, notification); } },
        React.createElement("div", { className: "notification-image-wrapper !align-baseline" },
            React.createElement(NotificationIcon, { notification: notification })),
        React.createElement("div", { className: "notification-text flex-1 !text-xs !font-normal !text-neutralPrimary !px-0" },
            React.createElement("p", { className: "my-0" }, (notification === null || notification === void 0 ? void 0 : notification.message) || "-"),
            React.createElement("p", { className: "my-0 from-time-text !text-[10px] !leading-3 !font-normal !text-secondary" }, (notification === null || notification === void 0 ? void 0 : notification.createdAt)
                ? formatNotificationTime(notification === null || notification === void 0 ? void 0 : notification.createdAt, currentLanguage)
                : ""),
            React.createElement("p", { className: "my-0 delete-icon" })),
        React.createElement("div", { className: "table-cell align-top mr-2 text-b" }, notification && (notification === null || notification === void 0 ? void 0 : notification.status) == 2 ? null : (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 16 16", width: "8px", fill: "none", height: "8px" },
            React.createElement("circle", { cx: "50%", cy: "50%", r: "4", fill: "rgb(37, 99, 235)" }))))));
};
export default NotificationItem;
