import { SUB_URL } from "config";
import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { isAuthenticated, navigateToUrl } from "utils/CommonUtil";
import { getItem, storeItem } from "utils/LocalStorageUtil";
import { LOGIN_REFERER } from "utils/StorageKeys";
var PublicRoute = function (_a) {
    var children = _a.children;
    var loginRefererURL = getItem(LOGIN_REFERER);
    var pathNames = ["/cart-detail", "/nfc", "/send-sanh-results"];
    if (loginRefererURL &&
        performance &&
        performance.getEntriesByType("navigation") &&
        performance.getEntriesByType("navigation").length &&
        performance.getEntriesByType("navigation")[0].type === "back_forward") {
        if (SUB_URL && loginRefererURL.includes(SUB_URL)) {
            var pathnames = loginRefererURL.split(SUB_URL);
            if (pathnames && pathnames.length) {
                loginRefererURL = pathnames[pathnames.length - 1];
            }
        }
        if (loginRefererURL == "/cart-detail") {
            loginRefererURL += "?payment-method";
        }
        navigateToUrl(loginRefererURL);
    }
    else {
        var location_1 = useLocation();
        var searchPurchasedUrl = "search-items";
        var pathName = location_1 && location_1.pathname ? location_1.pathname : "";
        if (pathName && (location_1 === null || location_1 === void 0 ? void 0 : location_1.search)) {
            pathName += location_1.search;
        }
        storeItem(LOGIN_REFERER, pathName);
        if (pathNames.includes(location_1 === null || location_1 === void 0 ? void 0 : location_1.pathname) && !isAuthenticated()) {
            return React.createElement(Navigate, { to: "/login" });
        }
        // else if (
        //   location?.pathname.includes(searchPurchasedUrl) &&
        //   isAuthenticated()
        // ) {
        //   return <Navigate to="" />;
        // }
    }
    return React.createElement(Outlet, null);
};
export default PublicRoute;
